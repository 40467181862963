import Block from "../../../base/Block";
import Registration from "../../../base/Registration";
import "./MainPage.scss";
class MainPage extends Block {
    static get blockName() {
        return "b-main-page";
    }
    onInit() {
        //
    }
}
Registration.register(MainPage);
export default MainPage;
